import React from 'react';
import { Toolbar } from 'components';
import GarrisonSelect from 'techfires/components/garrison-select/GarrisonSelect';
import { DatePicker, RecordSelect } from '@smartplatform/ui';
import store from 'client/store';
import { inject, observer } from 'mobx-react';
import t from '@smartplatform/map/i18n';

@inject('store')
@observer
export default class StatisticsToolbar extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	render() {
		const { garrison, onChangeStatsFilter, startDateStat, endDateStat, fireDepType, isPublic } = this.store;
		return (
			<Toolbar classNames='tfires-dashboard-toolbar'>
				<GarrisonSelect value={garrison} onChange={onChangeStatsFilter('garrison')} width={200} isPublic={isPublic} />
				<RecordSelect
					model={store.model.FireDepType}
					onChange={onChangeStatsFilter('fireDepType')}
					value={fireDepType}
					property='name'
					width={150}
					empty={t('all')}
				/>
				<DatePicker
					value={startDateStat}
					onChange={onChangeStatsFilter('startDateStat')}
					showTimeSelect={false}
					placeholder={t('date1')}
					showClearButton={false}
				/>
				<DatePicker value={endDateStat} onChange={onChangeStatsFilter('endDateStat')} showTimeSelect={false} placeholder={t('date2')} showClearButton={false} />
			</Toolbar>
		);
	}
}
