import React from 'react';
import './style.scss';
import { inject, observer } from 'mobx-react';
import { Loader } from '@smartplatform/ui';
import AlertTriangleIcon from 'img/icons/fires/dashboard/alert-triangle.svg';
import HomeLineIcon from 'img/icons/fires/dashboard/home-line.svg';
import EmergencySituationIcon from 'img/icons/fires/dashboard/emergency-situation.svg';
import { Card } from 'components';
import ArrowIntact from 'client/img/icons/techfires/arrows/arrow_intact.svg';
import MinusRed from 'client/img/icons/techfires/signs/minusRed.svg';
import MinusGreen from 'client/img/icons/techfires/signs/minusGreen.svg';
import PlusRed from 'client/img/icons/techfires/signs/plusRed.svg';
import PlusGreen from 'client/img/icons/techfires/signs/plusGreen.svg';
import t from 'i18n';

const CODE = 'DashboardStat';

@inject('store')
@observer
export default class StatisticsReport extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
		this.store.findOrCreateReport(CODE, t('techFire.statistics.dashboardStat'));
	}

	render() {
		const { isLoading, statData } = this.store;
		if (isLoading) return <Loader size={20} />;

		const data = statData.length ? statData[0] : null;

		return (
			<>
				<Card icon={<AlertTriangleIcon />} title={t('techFire.statistics.firesAndDamage')}>
					<div className='numbers-row'>
						<div className='number-block red'>
							<label className='block-label red'>{t('techFire.statistics.numberOfFires')}</label>
							<div className='number-container'>
								<div>
									<strong className='number-value'>{data ? data.totalfires || 0 : 0}</strong>
								</div>
								<div className='number-container-right'>
									<strong className='number-appg'>{data ? data.prevtotalfires || 0 : 0} {t('techFire.statistics.appg')}</strong>
									<em className={data?.percenttotalfires > 0 ? 'number-percent red' : data?.percenttotalfires < 0 ? 'number-percent green' : 'number-percent'}>
										{data?.percenttotalfires > 0 ? <PlusRed /> : data?.percenttotalfires < 0 ? <MinusGreen /> : <ArrowIntact />}
										{data?.percenttotalfires} %
									</em>
								</div>
							</div>
						</div>
						<div className='number-block red'>
							<label className='block-label red'>{t('techFire.statistics.fireDamage')}, {t('techFire.statistics.thousandRub')}</label>						
							<div className='number-container'>
								<div>
									<strong className='number-value'>{data ? data.totaldamage || 0 : 0}</strong>
								</div>	
								<div className='number-container-right'>
									<strong className='number-appg'>{data ? data.prevtotaldamage || 0 : 0} {t('techFire.statistics.appg')}</strong>
									<em className={data?.percenttotaldamage > 0 ? 'number-percent red' : data?.percenttotaldamage < 0 ? 'number-percent green' : 'number-percent'}>
										{data?.percenttotaldamage > 0 ? <PlusRed /> : data?.percenttotaldamage < 0 ? <MinusGreen /> : <ArrowIntact />}
										{data?.percenttotaldamage} %
									</em>
								</div>
							</div>
						</div>
						<div className='number-block green'>
							<label className='block-label green'>{t('techFire.statistics.materialAssetsSaved')}, {t('techFire.statistics.thousandRub')}</label>						
							<div className='number-container'>
								<div>
									<strong className='number-value'>{data ? data.totalsavedvalues || 0 : 0}</strong>
								</div>
								<div className='number-container-right'>
									<strong className='number-appg'>{data ? data.prevtotalsavedvalues || 0 : 0} {t('techFire.statistics.appg')}</strong>
									<em className={data?.percenttotalsavedvalues > 0 ? 'number-percent red' : data?.percenttotalsavedvalues < 0 ? 'number-percent green' : 'number-percent'}>
										{data?.percenttotalsavedvalues > 0 ? <PlusRed /> : data?.percenttotalsavedvalues < 0 ? <MinusGreen /> : <ArrowIntact />}
										{data?.percenttotalsavedvalues} %
									</em>
								</div>
							</div>
						</div>
					</div>
				</Card>
				<Card icon={<HomeLineIcon />} title={t('techFire.statistics.casualties')}>
					<div className='numbers-row'>
						<div className='number-block red'>
							<label className='block-label red'>{t('total')}</label>
							<div className='number-container'>
								<div>
									<strong className='number-value'>{data ? data.totaldeceased || 0 : 0}</strong>
								</div>
								<div className='number-container-right'>
									<strong className='number-appg'>{data ? data.prevtotaldeceased || 0 : 0} {t('techFire.statistics.appg')}</strong>
									<em className={data?.percenttotaldeceased > 0 ? 'number-percent red' : data?.percenttotaldeceased < 0 ? 'number-percent green' : 'number-percent'}>
										{data?.percenttotaldeceased > 0 ? <PlusRed /> : data?.percenttotaldeceased < 0 ? <MinusGreen /> : <ArrowIntact />}
										{data?.percenttotaldeceased} %
									</em>
								</div>
							</div>
						</div>
						<div className='number-block red'>
							<label className='block-label red'>{t('techFire.statistics.includingChildren')}</label>		
							<div className='number-container'>
								<div>				
									<strong className='number-value'>{data ? data.totalchilddeceased || 0 : 0}</strong>
								</div>
								<div className='number-container-right'>
									<strong className='number-appg'>{data ? data.prevtotalchilddeceased || 0 : 0} {t('techFire.statistics.appg')}</strong>
									<em className={data?.percenttotalchilddeceased > 0 ? 'number-percent red' : data?.percenttotalchilddeceased < 0 ? 'number-percent green' : 'number-percent'}>
										{data?.percenttotalchilddeceased > 0 ? <PlusRed /> : data?.percenttotalchilddeceased < 0 ? <MinusGreen /> :  <ArrowIntact />}
										{data?.percenttotalchilddeceased} %
									</em>
								</div>
							</div>
						</div>
						<div className='number-block green'>
							<label className='block-label green'>{t('techFire.statistics.peopleSaved')}</label>		
							<div className='number-container'>
								<div>					
									<strong className='number-value'>{data ? data.totalrescuedpeople || 0 : 0}</strong>
								</div>
								<div className='number-container-right'>
									<strong className='number-appg'>{data ? data.prevtotalrescuedpeople || 0 : 0} {t('techFire.statistics.appg')}</strong>
									<em className={data?.percenttotalrescuedpeople > 0 ? 'number-percent green' : data?.percenttotalrescuedpeople < 0 ? 'number-percent red' : 'number-percent'}>
										{data?.percenttotalrescuedpeople > 0 ? <PlusGreen /> : data?.percenttotalrescuedpeople < 0 ? <MinusRed /> : <ArrowIntact />}
										{data?.percenttotalrescuedpeople} %
									</em>
								</div>
							</div>
						</div>
					</div>
				</Card>
				<Card icon={<EmergencySituationIcon />} title={t('techFire.statistics.injuriesReceived')}>
					<div className='numbers-row'>
						<div className='number-block red'>
							<label className='block-label red'>{t('total')}</label>
							<div className='number-container'>
								<div>
									<strong className='number-value'>{data ? data.totalinjured || 0 : 0}</strong>
								</div>
								<div className='number-container-right'>
									<strong className='number-appg'>{data ? data.prevtotalinjured || 0 : 0} {t('techFire.statistics.appg')}</strong>
									<em className={data?.percenttotalinjured > 0 ? 'number-percent red' : data?.percenttotalinjured < 0 ? 'number-percent green' : 'number-percent'}>
										{data?.percenttotalinjured > 0 ? <PlusRed /> : data?.percenttotalinjured < 0 ? <MinusGreen /> : <ArrowIntact />}
										{data?.percenttotalinjured} %
									</em>
								</div>
							</div>
						</div>
						<div className='number-block red'>
							<label className='block-label red'>{t('techFire.statistics.includingChildren')}</label>		
							<div className='number-container'>		
								<div>	
									<strong className='number-value'>{data ? data.totalchildinjured || 0 : 0}</strong>
								</div>
								<div className='number-container-right'>
									<strong className='number-appg'>{data ? data.prevtotalchildinjured || 0 : 0} {t('techFire.statistics.appg')}</strong>
									<em className={data?.percenttotalchildinjured > 0 ? 'number-percent red' : data?.percenttotalchildinjured < 0 ? 'number-percent green' : 'number-percent'}>
										{data?.percenttotalchildinjured > 0 ?  <PlusRed /> : data?.percenttotalchildinjured < 0 ? <MinusGreen /> : <ArrowIntact />}
										{data?.percenttotalchildinjured} %
									</em>
								</div>
							</div>	
						</div>
						<div className='number-block green'>
							<label className='block-label green'>{t('techFire.statistics.peopleEvacuated')}</label>	
							<div className='number-container'>			
								<div>			
									<strong className='number-value'>{data ? data.totalsavedpeople || 0 : 0}</strong>
								</div>
								<div className='number-container-right'>
									<strong className='number-appg'>{data ? data.prevtotalsavedpeople || 0 : 0} {t('techFire.statistics.appg')}</strong>
									<em className={data?.percenttotalsavedpeople > 0 ? 'number-percent green' : data?.percenttotalsavedpeople < 0 ? 'number-percent red' : 'number-percent'}>
										{data?.percenttotalsavedpeople > 0 ? <PlusGreen /> : data?.percenttotalsavedpeople < 0 ? <MinusRed /> : <ArrowIntact />}
										{data?.percenttotalsavedpeople} %
									</em>
								</div>
							</div>
						</div>
					</div>
				</Card>
			</>
		);
	}
}