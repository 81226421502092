import React from 'react';
import { Style, Icon, Stroke } from 'ol/style';
import t from 'i18n';
import shipIconUrl from 'img/icons/shared/shipSelectedMarker.svg?url';
import { Vector as VectorSource } from 'ol/source';
import { observable } from 'mobx';
import { subMonths } from 'date-fns';
import { drawGeoMarker, geoJSON } from '@smartplatform/map/client';
import { Vector as VectorLayer } from 'ol/layer';
import store from 'client/store';
import { Feature } from 'ol';
import { fromLonLat } from 'ol/proj';
import { TimeLine } from './time-line/TimeLine';
import { LineString } from 'ol/geom';
import { Card } from 'components';
import { ShipPopup } from './ShipPopup';
import './shipTrackPopup.scss';
import ShipIcon from 'img/icons/delivery/ship.svg';
import { ShipTrackPointPopup } from './ShipTrackPointPopup';

export class ShipTrackLayer {
	@observable ship = null;
	@observable isInitialized = false;
	@observable isLoading = false;
	@observable startDate = subMonths(new Date(), 1);
	@observable endDate = new Date();
	@observable selectedDate = new Date();
	@observable dates = [];

	onDatesChange = (dates) => {
		for (const key of Object.keys(dates)) {
			this[key] = dates[key];
		}
		this.updateLayer();
	};

	onSelectedDateChange = (index) => {
		this.removeShipFeature();
		this.shipFeature = this.getPositionFeature(this.tracks[index]);
		this.source.addFeature(this.shipFeature);
	};

	removeShipFeature = () => this.source.removeFeature(this.shipFeature);

	constructor(mapStore, props) {
		this.mapStore = mapStore;
		this.props = props;
	}

	updateShip = (ship) => {
		this.removeShipFeature();
		this.ship = ship;
	};

	updateDate = (date) => (this.selectedDate = date);
	updateLayer = () => this.load();

	initLayer = async () => {
		this.layer = new VectorLayer({
			format: geoJSON,
			source: new VectorSource(),
		});
		this.layer.setZIndex(10);
		this.mapStore.addLayer(this.layer);
		this.source = this.layer.getSource();
		this.isInitialized = true;
	};

	getPositionFeature = (shipPosition) => {
		const { geo, course, name, nameLat } = shipPosition;

		const shipFeature = new Feature({
			isMarker: true,
			geometry: geoJSON.readGeometry(geo),
		});

		shipFeature.setStyle(
			new Style({
				image: new Icon({
					src: shipIconUrl,
					rotateWithView: true,
					scale: 0.8,
					rotation: (Math.PI / 180) * Number(course),
					anchor: [5, 12.5],
					anchorXUnits: 'pixels',
					anchorYUnits: 'pixels',
				}),
			})
		);
		return shipFeature;
	};

	load = async () => {
		const { startDate, endDate, selectedDate, ship } = this;
		if (startDate && endDate && selectedDate && ship) {
			this.isLoading = true;
			this.source.clear();
			const [shipPosition] = await store.model.ShipTrack.find({
				where: { shipId: ship.id, receiveDate: { lte: new Date(selectedDate) } },
				limit: 1,
				order: 'receiveDate DESC',
			});

			this.tracks = await store.model.ShipTrack.find({
				order: 'receiveDate ASC',
				where: {
					and: [
						{ receiveDate: { gte: new Date(startDate) } },
						{ receiveDate: { lte: new Date(endDate) } },
						{ shipId: ship.id },
						{ geo: { neq: null } },
						// { id: { neq: shipPosition?.id } },
					],
				},
			});
			this.dates = this.tracks.map((i) => i.receiveDate);

			const features = [];

			// кривая трека
			const trackLine = new Feature(new LineString(this.tracks.map((track) => fromLonLat(track.geo.coordinates))));
			trackLine.setStyle(new Style({ stroke: new Stroke({ color: 'black', width: 1 }) }));
			features.push(trackLine);

			// точки трека
			for (let track of this.tracks) {
				if (track.id === shipPosition?.id) {
					continue;
				}

				const trackPointFeature = new Feature({
					isMarker: true,
					geometry: geoJSON.readGeometry(track.geo),
					title: t('shipTrack.title') + ` ${this.ship.name || this.ship.nameLat}`,
					record: track,
					render: () => <ShipTrackPointPopup record={track} />,
				});

				features.push(trackPointFeature);
			}

			if (shipPosition) {
				this.shipFeature = this.getPositionFeature(shipPosition);
				features.push(this.shipFeature);
			}

			this.source.addFeatures(features);
			this.isLoading = false;
		}
	};

	setVisible = (boolean) => this.layer.setVisible(boolean);

	timeLineComponent = (props) => (
		<TimeLine
			// dates={this.dates}
			// onDatesChange={this.onDatesChange}
			// startDate={this.startDate}
			// endDate={this.endDate}
			// onSelectedDateChange={this.onSelectedDateChange}
			layer={this}
			{...props}
		/>
	);

	onClose = () => this.props.onClose();

	popupComponent = (props) => (
		<Card icon={<ShipIcon />} title={this.ship.nameLat} className='track-ship-popup'>
			<ShipPopup record={this.ship} isTrackPopup onClose={this.onClose} />
		</Card>
	);
}
