import React from 'react';
import DashboardStore from 'techfires/dashboard/store';
import Chart from 'chart.js';
import { Card } from 'components';
import LineChartIcon from 'img/icons/fires/dashboard/line-chart.svg';
import { Loader, Select } from '@smartplatform/ui';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

@observer
export default class CountEquipmentByType extends React.Component{

	@observable loading;
	constructor(props) {
		super(props);
		this.store = DashboardStore;
		this.store.fetchRecordsEquipmentByType();
	}

	onChange = async (value) => {
		this.loading = true;
		this.store.ChartEquipmentTypeFD = value;
		await this.store.fetchRecordsEquipmentByType();
		this.loading = false;
	}

	render(){

		const {fireDepartments, ChartEquipmentTypeFD, recordsEquipmentByType} = this.store;

		const onChart = (el) => {
			if(!recordsEquipmentByType.length) return null;
			if (!el) {
				if (this.Chart) this.Chart.destroy();
				return;
			}

			const style = {
				borderWidth: 0,
				pointRadius: 0.5,
				barPercentage: 0.9,
				categoryPercentage: 1.0,
				// barThickness: 40,
			};
			const dataChart = {
				labels: recordsEquipmentByType.map(equipment => ''),
				datasets: [{
					label: '',
					data: recordsEquipmentByType.map(equipment => equipment.count),
					backgroundColor: recordsEquipmentByType.map(equipment => equipment.color),
					borderColor: recordsEquipmentByType.map(equipment => equipment.color),
					...style
				}]
			};

			el.parentNode.scrollLeft = el.scrollWidth;

			this.Chart = new Chart(el, {
				type: 'horizontalBar',
				data: dataChart,
				options: {
					tooltips: {
						enabled: false,
					},
					hover: {
						mode: null,
					},
					responsive: true,
					maintainAspectRatio: false,
					legend: {
						display: false,
					},
					scales: {
						xAxes: [{ ticks: {
								beginAtZero: true
							}
						}],
					}
				},
			});
		};

		return(
			<Card icon={<LineChartIcon />} title='Количество техники в ПЧ (по типу)' className='chart-count-equipment'>
				<div className='filter-wrapper'>
					<div className='filter-item'>
						<label>Пожарная часть</label>
						<Select
							className='w-auto mr-2'
							noTotal
							maxItems={10}
							itemsPerPage={1000}
							noSearch
							isRequired
							items={fireDepartments}
							value={ChartEquipmentTypeFD}
							onChange={(fireDepartment) => this.onChange(fireDepartment)}
						/>
					</div>
				</div>
				{
					this.loading ?
						 <Loader />
						:
							!recordsEquipmentByType.length ? 'Нет данных'
							:
								<>
									<div className='chart-wrapper'>
										<canvas ref={onChart} />
									</div>
									<div className='legend'>
										{recordsEquipmentByType.map((equipment, index) => {
											return (
												<div className='item-legend' key={index}>
													<span className='color' style={{ backgroundColor: equipment.color }} />
													{equipment.typeName}
												</div>
											)
										})}
									</div>
								</>

				}
			</Card>
		);
	}
}