import { observable } from 'mobx';
import store from 'client/store';
import { startOfYear, endOfYear, startOfDay, endOfDay } from 'date-fns';
import { colors } from 'techfires/dashboard/constatnts';
import { triggerWarningNotification } from 'components/notifications';
import t from 'i18n';

class DashboardStore {
	@observable isLoading = true;
	@observable dataDay = [];
	@observable dataYear = {};
	@observable dataPreviousYear = {};
	@observable ChartEquipmentTypeFD = '';
	@observable ChartEquipmentStatusFD = '';
	@observable recordsEquipmentByType = {};
	@observable recordsEquipmentByStatus = {};
	@observable startDate = startOfYear(new Date());
	@observable endDate = new Date();
	statuses = {};
	statusesEquipment = {};
	@observable EquipmentByFD = {};
	fireDepartments = [];
	@observable startDateStat = startOfYear(new Date());
	@observable endDateStat = new Date();
	@observable fireDepType = null;
	@observable statData = [];

	@observable garrison = null;

	init = async () => {
		this.isLoading = true;
		if (!this.fireDepartments.length) {
			await this.fetchFireDepartments();
		}
		if (!this.statuses.length) {
			await this.fetchStatusFire();
		}
		if (!this.statusesEquipment.length) {
			await this.fetchStatusEquipment();
		}
		if (!this.statusesEquipment.length) {
			await this.fetchStatusEquipment();
		}
		await this.getDashboardStatData();
		this.isLoading = false;
	};

	fetchStatusFire = async () => {
		this.statuses = await store.model.FireDepStatus.find();
	};

	fetchStatusEquipment = async () => {
		this.statusesEquipment = await store.model.FireDepVehicleStatus.find();
	};

	fetchFireDepartments = async () => {
		const where = {};
		if (this.garrison) {
			where.garrisonId = this.garrison.id;
		}
		const fireDepartments = await store.model.ViewFireDep.find({ where: { ...where } });
		this.fireDepartments = fireDepartments.map((fireDepartment) => ({ label: fireDepartment.name, value: fireDepartment.id, code: fireDepartment.code }));
		this.ChartEquipmentTypeFD = this.fireDepartments[0]?.value || '';
		this.ChartEquipmentStatusFD = this.fireDepartments[0]?.value || '';
	};

	fetchDataDay = async () => {
		if (!this.statuses.length) return null;
		let today = new Date();
		today = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);
		let yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 8, 0, 0);
		const where = { and: [{ detectTime: { between: [yesterday, today] } }] };
		if (this.garrison) {
			const TechFireDepIds = await this.getFireDepsByGarrison();
			if (TechFireDepIds.length) where.and.push({ or: [{ fireDepId: { inq: TechFireDepIds } }, { garrisonId: this.garrison.id }] });
		}
		const records = await store.model.TechFire.find({
			where: { ...where },
		});
		this.dataDay = this.statuses.reduce((data, status, index) => {
			data.push({
				statusName: status.name,
				count: records.filter((record) => record.statusId === status.id).length,
				color: colors[index],
			});
			return data;
		}, []);
	};

	fetchRecordsEquipmentByStatus = async () => {
		if (!this.ChartEquipmentStatusFD || !this.statusesEquipment.length) return null;
		const where = {};
		const records = await store.model.FireDepVehicle.find({
			where: { fireDepId: this.ChartEquipmentStatusFD, fireDepShiftId: null },
		});

		this.recordsEquipmentByStatus = this.statusesEquipment.reduce((data, status, index) => {
			data.push({
				statusName: status.name,
				count: records.filter((record) => record.vehicleStatusId === status.id).length,
				color: colors[index],
			});
			return data;
		}, []);
	};

	fetchRecordsEquipmentByType = async () => {
		if (!this.ChartEquipmentTypeFD) return null;

		const records = await store.model.FireDepVehicle.find({
			where: { fireDepId: this.ChartEquipmentTypeFD, fireDepShiftId: null },
		});

		const typeVehicle = await store.model.FireDepVehicleType.find({
			fields: ['id', 'name'],
		});

		this.recordsEquipmentByType = typeVehicle.reduce((data, type, index) => {
			let countVehicle = records.filter((record) => record.typeId === type.id).length;
			if (!countVehicle) return data;
			data.push({
				typeName: type.name,
				count: countVehicle,
				color: colors[data.length],
			});
			return data;
		}, []);
	};

	fetchRecordsFireByPeriod = async () => {
		if (!this.startDate || !this.endDate || !this.statuses.length) return null;

		const startDatePrevious = new Date(new Date(this.startDate).setFullYear(new Date(this.startDate).getFullYear() - 1));
		const endDatePrevious = new Date(new Date(this.endDate).setFullYear(new Date(this.endDate).getFullYear() - 1));

		const where = { and: [{ detectTime: { between: [this.startDate, this.endDate] } }] };
		const wherePrevious = { and: [{ detectTime: { between: [startDatePrevious, endDatePrevious] } }] };

		if (this.garrison) {
			const TechFireDepIds = await this.getFireDepsByGarrison();
			if (TechFireDepIds.length) {
				where.and.push({ or: [{ fireDepId: { inq: TechFireDepIds } }, { garrisonId: this.garrison.id }] });
				wherePrevious.and.push({ or: [{ fireDepId: { inq: TechFireDepIds } }, { garrisonId: this.garrison.id }] });
			}
		}

		const records = await store.model.TechFire.find({ where: { ...where } });
		const recordsPrevious = await store.model.TechFire.find({ where: { ...wherePrevious } });

		this.dataYear = this.statuses.reduce((data, status, index) => {
			data.push({
				statusName: status.name,
				count: records.filter((record) => record.statusId === status.id).length,
			});
			return data;
		}, []);
		this.dataPreviousYear = this.statuses.reduce((data, status, index) => {
			data.push({
				statusName: status.name,
				count: recordsPrevious.filter((record) => record.statusId === status.id).length,
			});
			return data;
		}, []);
	};

	fetchDataVehicleByFD = async () => {
		const where = {};
		if (this.garrison) {
			where.garrisonId = this.garrison.id;
		}
		const fireDeps = await store.model.ViewFireDep.find({ where: { ...where } });
		const vehicles = await store.model.FireDepVehicle.find({
			where: { fireDepShiftId: null },
			include: ['type', 'status'],
		});
		this.EquipmentByFD = fireDeps.reduce((data, fireDep, index) => {
			let vehiclesByFireDep = vehicles.filter((vehicle) => vehicle.fireDepId === fireDep.id);
			if (!vehiclesByFireDep.length) return data;
			vehiclesByFireDep = vehiclesByFireDep.map((vehicle) => {
				return {
					number: vehicle?.stateNumber || '-',
					type: vehicle?.type?.name || '-',
					status: vehicle?.status?.name || '-',
				};
			});
			data.push({
				name: fireDep.name,
				items: vehiclesByFireDep,
			});
			return data;
		}, []);
	};

	onChange = (prop) => async (value) => {
		this[prop] = value;
		this.fetchDataDay();
		await this.fetchFireDepartments();
		this.fetchRecordsEquipmentByStatus();
		this.fetchRecordsFireByPeriod();
		this.fetchDataVehicleByFD();
	};

	onChangeStatsFilter = (prop) => async (value) => {
		this[prop] = value;

		if (prop === 'startDateStat' || prop === 'endDateStat') {
			// сообщим если период не заполнен
			if (!this.startDateStat || !this.endDateStat) {
				triggerWarningNotification(t('techFire.statistics.emptyPeriod'));
			}

			// сообщим если период превышает год, т.к. в отчете сравнивается год к году
			if (this.startDateStat?.getFullYear() != this.endDateStat?.getFullYear()) {
				triggerWarningNotification(t('techFire.statistics.recommendedPeriod'));
			}
		}
		
		await this.getDashboardStatData();
	};

	getFireDepsByGarrison = async () => {
		return (await store.model.ViewFireDep.find({ where: { garrisonId: this.garrison.id }, fields: ['id'] })).map((r) => r.id);
	};

	get storeParams() {
		const params = { reportCode: 'DashboardStat' };
		const reportParams = {};

		reportParams.startDate = this.startDateStat;
		reportParams.endDate = this.endDateStat;
		reportParams.garrison = this.garrison;
		reportParams.fireDepType = this.fireDepType;

		if (Object.keys(reportParams).length) params.reportParams = reportParams;

		return params;
	}

	findOrCreateReport = async (code, name) => {
		if (code) {
			const report = await store.model.TechFireReport.find({ where: { code: code } });
			this.report = report[0] ?? (await store.model.TechFireReport.create({ code: code, name: name }));
			if (!this.report.name && this.code) {
				this.report.name = name;
				this.report.save();
			}
		}
	};

	getDashboardStatData = async () => {
		this.isLoading = true;
		const { data } = await store.model.TechFireReport.getReport(this.storeParams);
		this.statData = data;
		this.isLoading = false;
	};
}

export default new DashboardStore();
