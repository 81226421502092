import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import { createXYZ } from 'ol/tilegrid';
import { get as getProj } from 'ol/proj';
import MVT from 'ol/format/MVT';
import { Icon, Style, Text } from 'ol/style';
import t from 'i18n';
import React from 'react';
import { OperationalManagementPopup } from './OperationalManagementPopup';
import { observable } from 'mobx';
import debounce from 'lodash/debounce';
import markerIcon from 'img/icons/shared/operationalManagementMarker.svg?url';

const TILE_SIZE = 512;
export class OperationalManagementLayer {
	@observable search = '';

	constructor(mapStore, settings = {}) {
		this.mapStore = mapStore;
		this.settings = settings;
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
	}

	onChangeSearch = (value) => {
		this.search = value;
		this.doSearch();
	};

	doSearch = () => {
		this.source.refresh();
	};

	init = async () => {
		this.source = new VectorTileSource({
			format: new MVT(),
			tileGrid: createXYZ({ extent: getProj('EPSG:3857').getExtent(), maxZoom: 18, tileSize: TILE_SIZE }),
			tileUrlFunction: this.tileUrlFunction,
			tileLoadFunction: this.tileLoadFunction(),
		});

		this.layer = new VectorTileLayer({ source: this.source, style: this.getStyle });

		this.layer.setZIndex(3);
		this.setVisible();
		this.mapStore.addLayer(this.layer);
	};
	getStyle = (feature) => {
		const { record } = feature.getProperties();
		const { operationalmanagementname = '', name: objectname = '' } = record;
		let text = objectname;

		if (operationalmanagementname) {
			text = objectname ? `${operationalmanagementname} - ${objectname}` : operationalmanagementname;
		}

		return new Style({
			image: new Icon({ src: markerIcon, anchor: [10, 31], anchorXUnits: 'pixels', anchorYUnits: 'pixels', scale: 0.8 }),
			text: new Text({ text, scale: 1.5, offsetY: 14, offsetX: 7, textAlign: 'center' }),
		});
	};

	tileUrlFunction = (coords) => {
		const trimmedString = this.search.trim().replace(/'/g, `''`);
		let filter = `&filter= (statusCode != 'CLOSED' or statusCode is null)`;

		if (trimmedString) {
			filter += `and ( name ilike '%25${trimmedString}%25' or operationalManagementName ilike '%25${trimmedString}%25' )`;
		}

		return `/api/mvt?model=ViewOperationalManagementObject&x=${coords[1]}&y=${coords[2]}&z=${coords[0]}${filter}&noCache=1&columns=id,name,operationalmanagementname&buffer=${TILE_SIZE}`;
	};

	tileLoadFunction = (render) => (tile, url) => {
		const settings = this.settings;
		tile.setLoader(function (extent, resolution, projection) {
			fetch(url).then(function (response) {
				response.arrayBuffer().then(function (data) {
					const format = tile.getFormat(); // ol/format/MVT configured as source format
					const renderFeatures = format.readFeatures(data, {
						extent: extent,
						featureProjection: projection,
					});

					renderFeatures.forEach((f) => {
						const record = f.getProperties();
						f.properties_ = {
							title: t('operationalManagementObject.title') + ' №' + record.id,
							record,
							render: (record) => <OperationalManagementPopup record={record} noPopupLink={settings.noPopupLink} />,
						};
					});

					tile.setFeatures(renderFeatures);
				});
			});
		});
	};

	setVisible = () => this.layer.setVisible(this.settings.show);
}
