import React from 'react';
import Root from './Root';
import TechFiresIcon from 'img/icons/menu/techfires.svg';
import MapIcon from 'img/icons/sidebar/shared/map.svg';
import FireIcon from 'img/icons/sidebar/shared/fire.svg';
import FireDepIcon from 'img/icons/sidebar/techfires/fireDep.svg';
import NoteIcon from 'img/icons/sidebar/techfires/note.svg';
import RegistryIcon from 'img/icons/sidebar/techfires/registry.svg';
import BellIcon from 'img/icons/sidebar/techfires/bell.svg';
import ReportIcon from 'img/icons/sidebar/techfires/report.svg';
import TransportData from 'img/icons/sidebar/shared/transport-data.svg';
import AdminIcon from 'img/icons/sidebar/shared/admin.svg';
import DictionaryIcon from 'img/icons/sidebar/shared/dictionary.svg';
import ShiftIcon from 'img/icons/shift.svg';
import WeatherIcon from 'img/icons/sidebar/shared/registry.svg';
import AddressIcon from 'img/icons/sidebar/shared/address.svg';
import DashboardIcon from 'img/icons/sidebar/shared/dashboard.svg';
// import SentryIcon from 'img/icons/sentry.svg';
import t from 'i18n';
import tMap from '@smartplatform/map/i18n';
import store from 'client/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import JourneyIcon from 'img/icons/sidebar/shared/journey.svg';
import EmergencyIcon from 'img/icons/sidebar/fires/emergency.svg';
import { hasAccessToModel } from '../../tools';

export const path = '/techfires';

export default {
	name: t('module.techfires'),
	code: 'techfires',
	icon: <TechFiresIcon />,
	path,
	component: Root,
	access: () => hasAccessToModel('FireDep'),
	menu: () => [
		{ title: t('techFire.dashboard.title'), path: `${path}/dashboard`, icon: <DashboardIcon /> },
		//{ title: t('techFire.weather.title'), path: `${path}/weather`, icon: <WeatherIcon /> },
		{ title: t('techFire.map.title'), path: `${path}/map`, icon: <MapIcon />, exact: true },
		hasAccessToModel('TechFire') ? { title: t('techFire.fires.titleDepartures'), path: `${path}/fires`, icon: <FireIcon /> } : undefined,
		// { title: t('fireDep.plural'), path: `${path}/firedep`, icon: <FontAwesomeIcon icon={faFireExtinguisher} style={{ fontSize: 26, marginRight: -5 }} /> },
		hasAccessToModel('FireDep') ? { title: t('fireDep.plural'), path: `${path}/firedep`, icon: <FireDepIcon /> } : undefined,
		{ title: t('fireDepNote.title'), path: `${path}/note`, icon: <NoteIcon /> },
		hasAccessToModel('ViewTechFireObject') ? { title: t('techFireAddress.registry'), path: `${path}/addresses-objects`, icon: <AddressIcon /> } : undefined,
		{ title: t('registries'), path: `${path}/registry`, icon: <RegistryIcon /> },
		{ title: t('fireDepShift.plural'), path: `${path}/shifts`, icon: <ShiftIcon /> },
		hasAccessToModel('FireDepPersonnel') ? { title: t('techFire.calls.title'), path: `${path}/calls`, icon: <EmergencyIcon /> } : undefined,
		hasAccessToModel('FireAlarmMessage') ? { title: t('fireNotifier'), path: `${path}/notifier`, icon: <BellIcon /> } : undefined,
		{ title: t('report.plural'), path: `${path}/reports`, icon: <ReportIcon /> },
		hasAccessToModel('NotifySchedule') ? { title: t('transportData.title'), path: `${path}/transport`, icon: <TransportData /> } : undefined,
		{ title: t('dictionaries.plural'), path: `${path}/dictionaries`, icon: <DictionaryIcon /> },
		{ title: t('audit.title'), path: `${path}/journal`, icon: <JourneyIcon /> },
		{ title: tMap('map.layer.plural'), path: `/layers`, icon: <FontAwesomeIcon icon={faLayerGroup} style={{ fontSize: 26 }} /> },
		store.isAdmin ? { title: t('admin.titleShort'), path: `${path}/admin`, icon: <AdminIcon /> } : undefined,
	],
};
